import React, { useState } from "react";
import { Spin, Upload, Button, message, Row, Col, Select, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Invoice from "./Invoice";
import Receipt from "./Receipt";
import dayjs from "dayjs";

const { Option } = Select;

const UploadInvoice = ({ refreshInvoices }) => {
  const [uploadType, setUploadType] = useState("invoice");
  const [month, setMonth] = useState(dayjs().format("MM"));
  const [year, setYear] = useState(dayjs().format("YYYY"));
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  console.log(data);
  const handleUpload = ({ file }) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadType", uploadType);
    formData.append("month", month);
    formData.append("year", year);

    fetch("https://visionai.lootea.cz/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 504) {
          throw new Error("Server timeout. Please wait while we retry.");
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((data) => {
        if (data.job_id) {
          pollJobStatus(data.job_id);
        } else {
          throw new Error("No job ID received from server");
        }
      })
      .catch((error) => {
        console.error("Error response from server:", error);
        message.error(
          `Error processing the document. See console for details.`
        );
        setLoading(false);
      });
  };

  const pollJobStatus = (jobId, retryCount = 0) => {
    setTimeout(() => {
      fetch(`https://visionai.lootea.cz/status/${jobId}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 504 && retryCount < 3) {
            return new Promise((resolve) => {
              setTimeout(
                () => resolve(pollJobStatus(jobId, retryCount + 1)),
                15000
              );
            });
          } else {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
        })
        .then((data) => {
          if (data.state === "SUCCESS") {
            setData({ ...data.result, fileName: data.filename });
            setLoading(false);
            setShowConfirmation(true);
            message.success(
              "Document processed successfully. Please review and confirm."
            );
          } else if (data.state === "PROGRESS") {
            message.info(`Current progress: ${data.progress}`);
            pollJobStatus(jobId);
          } else if (data.state === "FAILURE") {
            console.error("Error during processing:", data);
            message.error(
              `Processing failed: ${data.exc_type} - ${data.exc_message}`
            );
            setLoading(false);
          } else if (data.state === "PENDING") {
            pollJobStatus(jobId);
          } else {
            setLoading(false);
            message.info(`Job status: ${data.state}`);
          }
        })
        .catch((error) => {
          console.error("Error checking job status:", error);
          message.error(`Error checking job status. See console for details.`);
          setLoading(false);
        });
    }, 2000);
  };

  const handleConfirm = () => {
    setLoading(true);
    fetch("https://api.lootea.cz/api/invoices/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uploadType,
        month,
        year,
        fileName: data.fileName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to save data");
        }
      })
      .then(() => {
        message.success("Data successfully saved to database.");
        setShowConfirmation(false);
        setData(null);
        refreshInvoices();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        message.error("Failed to save data. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setData(null);
  };

  return (
    <div className="upload-container">
      <h1>Nahrát dokument </h1>

      {/* Upload Type Selection */}
      <Row justify="left" style={{ marginBottom: "20px", marginTop: "10px" }}>
        <Col>
          <Select
            defaultValue={uploadType}
            onChange={(value) => setUploadType(value)}
            style={{ width: 200 }}
          >
            <Option value="invoice">Invoice</Option>
            <Option value="receipt">Receipt</Option>
          </Select>
        </Col>
        <Col>
          <Select
            defaultValue={month}
            onChange={(value) => setMonth(value)}
            style={{ width: 120 }}
          >
            {dayjs.months().map((month, index) => (
              <Option
                key={index}
                value={(index + 1).toString().padStart(2, "0")}
              >
                {month}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            defaultValue={year}
            onChange={(value) => setYear(value)}
            style={{ width: 120 }}
          >
            {[...Array(10).keys()].map((i) => (
              <Option key={i} value={(dayjs().year() - i).toString()}>
                {dayjs().year() - i}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row justify="left">
        <Col>
          <Spin spinning={loading}>
            <Upload
              accept=".pdf,image/*"
              beforeUpload={(file) => {
                handleUpload({ file });
                return false;
              }}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Spin>
        </Col>
      </Row>

      {data && (
        <Modal
          title="Potvrdit údaje"
          open={showConfirmation}
          onOk={handleConfirm}
          onCancel={handleCancel}
          width={1000}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                flex: 1,
                marginRight: 20,
                maxHeight: "60vh",
                overflowY: "auto",
              }}
            >
              {/* Zobrazení obrázku faktury/účtenky */}
              {(data.invoice_image || data.receipt_image) &&
                (data.invoice_image || data.receipt_image).map(
                  (image, index) => (
                    <img
                      key={index}
                      src={`data:image/png;base64,${image}`}
                      alt={`Document page ${index + 1}`}
                      style={{ width: "100%", marginBottom: 10 }}
                    />
                  )
                )}
            </div>
            <div style={{ flex: 1, maxHeight: "60vh", overflowY: "auto" }}>
              {/* Zobrazení zpracovaných údajů */}
              {uploadType === "invoice" && <Invoice {...data} />}
              {uploadType === "receipt" && <Receipt {...data} />}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UploadInvoice;
