import React, { useState } from "react";
import { Checkbox, Tooltip, Tabs, Image } from "antd";
import "./faq.scss";

function FAQ() {
  const [isClicked, setIsClicked] = useState(false);
  const [checkedState, setCheckedState] = useState(() => {
    const saved = localStorage.getItem("checkboxState");
    return saved ? JSON.parse(saved) : {};
  });


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const newState = { ...checkedState, [name]: checked };
    setCheckedState(newState);
    localStorage.setItem("checkboxState", JSON.stringify(newState));
  };

  const resetCheckboxes = () => {
    setIsClicked(!isClicked);
    setCheckedState({});
    localStorage.setItem("checkboxState", JSON.stringify({}));
    setTimeout(() => {
      setIsClicked(isClicked);
    }, 300);
  };

  const tabsItems = [
    {
      label: "Po příchodu",
      key: "1",
      children: (
        <>
          <Checkbox
            name="computer"
            checked={checkedState["computer"]}
            onChange={handleCheckboxChange}
          >
            <Tooltip title="Heslo 2023" placement="top">
              Počítač 🖥️
            </Tooltip>
          </Checkbox>
          <Checkbox
            name="cashSystem"
            checked={checkedState["cashSystem"]}
            onChange={handleCheckboxChange}
          >
            <Tooltip title="Heslo admin" placement="top">
              Pokladní systém 📱
            </Tooltip>
          </Checkbox>
          {/*           <Checkbox
            name="treeLights"
            checked={checkedState["treeLights"]}
            onChange={handleCheckboxChange}
          >
            Světla na Stromečku 🎄
          </Checkbox>
          <Checkbox
            name="ceilingLights"
            checked={checkedState["ceilingLights"]}
            onChange={handleCheckboxChange}
          >
            Vánoční světla na stropě 💡
          </Checkbox>
          <Checkbox
            name="doorLights"
            checked={checkedState["doorLights"]}
            onChange={handleCheckboxChange}
          >
            Vánoční světla ve dveřích 💡
          </Checkbox> */}
          <Checkbox
            name="coffeeMaker"
            checked={checkedState["coffeeMaker"]}
            onChange={handleCheckboxChange}
          >
            Kávovar ☕
          </Checkbox>
          <Checkbox
            name="sugar"
            checked={checkedState["sugar"]}
            onChange={handleCheckboxChange}
          >
            Fruktóza{" "}
          </Checkbox>
          <Checkbox
            name="waterHeater"
            checked={checkedState["waterHeater"]}
            onChange={handleCheckboxChange}
          >
            Ohřívač vody 🌡️
          </Checkbox>
          <Checkbox
            name="sealing"
            checked={checkedState["sealing"]}
            onChange={handleCheckboxChange}
          >
            Sealing 🧋
          </Checkbox>
          <Checkbox
            name="television"
            checked={checkedState["television"]}
            onChange={handleCheckboxChange}
          >
            Televize 📺
          </Checkbox>
          {/*           <Checkbox
            name="wineHeater"
            checked={checkedState["wineHeater"]}
            onChange={handleCheckboxChange}
          >
            Vařič na víno 🍷
          </Checkbox> */}
        </>
      ),
    },
    {
      label: "Vaření",
      key: "2",
      children: (
        <>
          <Checkbox
            name="tapioca"
            checked={checkedState["tapioca"]}
            onChange={handleCheckboxChange}
          >
            Tapioku
          </Checkbox>
          <Checkbox
            name="greenTea"
            checked={checkedState["greenTea"]}
            onChange={handleCheckboxChange}
          >
            Zelený čaj 7l
          </Checkbox>
          <Checkbox
            name="blackTea"
            checked={checkedState["blackTea"]}
            onChange={handleCheckboxChange}
          >
            Černý čaj 5-7l / Ohřát
          </Checkbox>
          <Checkbox
            name="prepare"
            checked={checkedState["prepare"]}
            onChange={handleCheckboxChange}
          >
            Připravit vodu, čaje a tapioku na další den
          </Checkbox>
        </>
      ),
    },
    {
      label: "Úklid",
      key: "3",
      children: (
        <>
          <Checkbox
            name="sweepFloor"
            checked={checkedState["sweepFloor"]}
            onChange={handleCheckboxChange}
          >
            Zamést podlahu
          </Checkbox>
          <Checkbox
            name="mopFloor"
            checked={checkedState["mopFloor"]}
            onChange={handleCheckboxChange}
          >
            Vytřít podlahu
          </Checkbox>
          <Checkbox
            name="wipeUnderWindow"
            checked={checkedState["wipeUnderWindow"]}
            onChange={handleCheckboxChange}
          >
            Utřít pod oknem
          </Checkbox>
          <Checkbox
            name="cleanToppingShowcase"
            checked={checkedState["cleanToppingShowcase"]}
            onChange={handleCheckboxChange}
          >
            Umýt výlohu na toppingy
          </Checkbox>
          <Checkbox
            name="dustMachines"
            checked={checkedState["dustMachines"]}
            onChange={handleCheckboxChange}
          >
            Otřít prach na strojích a kolem
          </Checkbox>
          <Checkbox
            name="dustTVsMonitors"
            checked={checkedState["dustTVsMonitors"]}
            onChange={handleCheckboxChange}
          >
            Otřít prach na televizích, monitorech
          </Checkbox>
          <Checkbox
            name="cleanIceCreamShowcase"
            checked={checkedState["cleanIceCreamShowcase"]}
            onChange={handleCheckboxChange}
          >
            Umýt výlohu na zmrzlinu
          </Checkbox>
        </>
      ),
    },
    {
      label: "Kontrola",
      key: "4",
      children: (
        <>
          <Checkbox
            name="creamcheese"
            checked={checkedState["creamcheese"]}
            onChange={handleCheckboxChange}
          >
            Creamcheese
          </Checkbox>
          <Checkbox
            name="whippedcream"
            checked={checkedState["whippedcream"]}
            onChange={handleCheckboxChange}
          >
            Šlehačka
          </Checkbox>
          <Checkbox
            name="waterInBoiler"
            checked={checkedState["waterInBoiler"]}
            onChange={handleCheckboxChange}
          >
            Voda ve vařiči
          </Checkbox>
          <Checkbox
            name="waterInCoffeeMaker"
            checked={checkedState["waterInCoffeeMaker"]}
            onChange={handleCheckboxChange}
          >
            Voda v kávovaru
          </Checkbox>
          <Checkbox
            name="cupsRefilled"
            checked={checkedState["cupsRefilled"]}
            onChange={handleCheckboxChange}
          >
            Doplněné kelímky
          </Checkbox>
          <Checkbox
            name="strawsNapkinsRefilled"
            checked={checkedState["strawsNapkinsRefilled"]}
            onChange={handleCheckboxChange}
          >
            Doplněné brčka a ubrousky
          </Checkbox>
          <Checkbox
            name="toppingsRefilledAt12"
            checked={checkedState["toppingsRefilledAt12"]}
            onChange={handleCheckboxChange}
          >
            12:00 Doplnění topingů
          </Checkbox>
          <Checkbox
            name="teaCheckAt13"
            checked={checkedState["teaCheckAt13"]}
            onChange={handleCheckboxChange}
          >
            13:00 Kontrola čajů
          </Checkbox>
          <Checkbox
            name="tapiocaCheckAt14"
            checked={checkedState["tapiocaCheckAt14"]}
            onChange={handleCheckboxChange}
          >
            14:00 Kontrola tapioky
          </Checkbox>
          <Checkbox
            name="teaCheckAt15"
            checked={checkedState["teaCheckAt15"]}
            onChange={handleCheckboxChange}
          >
            15:00 Kontrola čajů
          </Checkbox>
          <Checkbox
            name="toppingsRefilledAt16"
            checked={checkedState["toppingsRefilledAt16"]}
            onChange={handleCheckboxChange}
          >
            16:00 Doplnění topingů
          </Checkbox>
        </>
      ),
    },
    {
      label: "Před odchodem",
      key: "5",
      children: (
        <>
          {/*  <Checkbox
            name="treeLightsOff"
            checked={checkedState["treeLightsOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout světla na Stromečku 🎄
          </Checkbox>
          <Checkbox
            name="ceilingLightsOff"
            checked={checkedState["ceilingLightsOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout Vánoční světla na stropě 💡
          </Checkbox>
          <Checkbox
            name="doorLightsOff"
            checked={checkedState["doorLightsOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout Vánoční světla ve dveřích 💡
          </Checkbox> */}
          <Checkbox
            name="coffeeMakerOff"
            checked={checkedState["coffeeMakerOff"]}
            onChange={handleCheckboxChange}
          >
            <Tooltip title="Heslo je 1809" placement="top">
              Vyčistit a vypnout kávovar ☕
            </Tooltip>
          </Checkbox>
          <Checkbox
            name="sealingOff"
            checked={checkedState["sealingOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout Sealing 🧋
          </Checkbox>
          <Checkbox
            name="waterHeaterOff"
            checked={checkedState["waterHeaterOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout ohřívač vody 🌡️
          </Checkbox>
          <Checkbox
            name="tvOff"
            checked={checkedState["tvOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout televize 📺
          </Checkbox>
          {/*           <Checkbox
            name="wineHeaterOff"
            checked={checkedState["wineHeaterOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout vařič na víno 🍷
          </Checkbox> */}
          <Checkbox
            name="mainDoorLocked"
            checked={checkedState["mainDoorLocked"]}
            onChange={handleCheckboxChange}
          >
            Zamknout hlavní přední dveře 🔒
          </Checkbox>
          {/*           <Checkbox
            name="wineHeaterCleaned"
            checked={checkedState["wineHeaterCleaned"]}
            onChange={handleCheckboxChange}
          >
            Umýt Vařič na víno🚿
          </Checkbox> */}
          <Checkbox
            name="mixerBowlCleaned"
            checked={checkedState["mixerBowlCleaned"]}
            onChange={handleCheckboxChange}
          >
            Umýt mísu s mixérem na drinky🚿
          </Checkbox>
          <Checkbox
            name="shakerBowlCleaned"
            checked={checkedState["shakerBowlCleaned"]}
            onChange={handleCheckboxChange}
          >
            Umýt mísu na mytí shakerů🚿
          </Checkbox>
          <Checkbox
            name="tapiocaPrepared"
            checked={checkedState["tapiocaPrepared"]}
            onChange={handleCheckboxChange}
          >
            <Tooltip title="1-4 a 4-4 + větši kapka BS" placement="top">
              Umýt a připravit vařič na tapioku 🚿{" "}
            </Tooltip>
          </Checkbox>
          <Checkbox
            name="cashCounted"
            checked={checkedState["cashCounted"]}
            onChange={handleCheckboxChange}
          >
            Spočítat kasu 💵
          </Checkbox>
          <Checkbox
            name="tabletsOff"
            checked={checkedState["tabletsOff"]}
            onChange={handleCheckboxChange}
          >
            Vypnout Tablety 💻
          </Checkbox>
          <Checkbox
            name="trashTakenOut"
            checked={checkedState["trashTakenOut"]}
            onChange={handleCheckboxChange}
          >
            Vynést koše 🗑️
          </Checkbox>
          <Checkbox
            name="acChecked"
            checked={checkedState["acChecked"]}
            onChange={handleCheckboxChange}
          >
            Zkontrolovat zda je vypnutá klimatizace❗
          </Checkbox>
        </>
      ),
    },
  ];
  return (
    <div className="FAQContainer">
      {" "}
      <div className="headerAndTabsContainer">
        <div className="header">
          <h2>Seznam úkolů k dosažení jistoty a svobody</h2>
          <img
            src={
              isClicked ? "/restartButtonPushed.webp" : "/restartButton.webp"
            }
            onClick={resetCheckboxes}
            className={`reset-button ${isClicked ? "clicked" : ""}`}
            alt="restart button"
          />
        </div>
        <Tabs defaultActiveKey="1" items={tabsItems} />
      </div>
      <div className="onlyFaqContainer">
        <h4>What if?</h4>
        <div className="overall">
          <ul>
            <li style={{ listStyleType: "none" }}>
              1. Pokud budeš chtít na toaletu, zavřit si venkovní dveře
            </li>
            <li style={{ listStyleType: "none" }}>
              2. Pokud spadnou pojistky, podívej se na naše pojistky za
              sealingem, pokud jsou nahoře (nahoře = zapnuté, dole = vypnuté),
              tak běž do zadu jak je vjezd, jsou to první větší skleněná
              "dvířka" otevírají se ze spod na pravé straně, naše pojistky jsou
              "Galerie" viz foto.
            </li>
            <div className="images-row">
              <Image width={50} src="/pojistky/pojistky1.jpeg" />
              <Image width={50} src="/pojistky/pojistky2.jpeg" />
              <Image width={50} src="/pojistky/pojistky3.jpeg" />
              <Image width={50} src="/pojistky/pojistky4.jpeg" />
            </div>
            <li style={{ listStyleType: "none" }}>
              3. Pokud ti nejde zapnout tepelná clona skrze webové rozhraní,
              klikni na jednu a počkej 2s, někdy to trvá.
            </li>
            <li style={{ listStyleType: "none" }}>
              4. Pokud se stane něco neočekávaného, volejte Tomáš Dinh 775290280
              nebo Patrik Dinh 720279090, kdykoliv.
            </li>
          </ul>

          <span></span>
          <span></span>
        </div>
        <div className="faqSegments">
          <h4>Delivery</h4>
          <div className="faqWolt">
            <h4>Wolt</h4>
            <ul>
              <li style={{ listStyleType: "none" }}>
                1. Pokud objednávka trvá dele než 20-30min (kurýr není nebo má
                zpoždení) kontaktujeme podporu, že budeme znova muset vyrobit
                drinky.
              </li>
              <li style={{ listStyleType: "none" }}>
                2. Kávy a drinky s hodně ledem děláme co možná nejpozději před
                příjezdem řidiče.
              </li>
            </ul>
          </div>
          {/*           <div className="faqBolt">
            <h4>Bolt</h4>{" "}
            <ul>
              <li style={{ listStyleType: "none" }}>
                1. Pokud objednávka trvá dele než 20-30min kontaktujeme podporu,
                že budeme znova muset vyrobit drinky.
              </li>
              <li style={{ listStyleType: "none" }}>
                2. Kávy a drinky s hodně ledem děláme co možná nejpozději před
                příjezdem řidiče.
              </li>
            </ul>
          </div> */}
          <div className="faqNesnezeno">
            <ul>
              <h4>Nesnězeno</h4>
              <li style={{ listStyleType: "none" }}>
                1. Neustále tě odhlašuje a kdykoliv přepneš stránku z Nesnězeno?
                Použij prohlížeč Firefox.
              </li>
              <li style={{ listStyleType: "none" }}>
                2. Důvod proč spolupracujeme je ten, že chceme snížit počet čaje
                co zůstane na konci dne
              </li>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
  );
}

export default FAQ;
