import React, { useState, useEffect } from "react";
import {
  Button,
  notification,
  DatePicker,
  Modal,
  Form,
  Input,
  Select,
  List,
  TimePicker,
  Checkbox,
} from "antd";
import dayjs from "dayjs";

import { useSocket } from "../../SocketProvider";
import EmployeesGraph from "./EmployeesGraph";
import locale from "antd/lib/date-picker/locale/cs_CZ";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "./employees.scss";
const { RangePicker } = DatePicker;
dayjs.extend(isSameOrAfter);

function Employees() {
  const [employee, setEmployee] = useState([]);
  const [employeeWork, setEmployeeWork] = useState([]);
  const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] =
    useState(false);
  const [selectedDates, setSelectedDates] = useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [isNonActiveChecked, setIsNonActiveChecked] = useState(false);
  const [isWorkDaysModalVisible, setIsWorkDaysModalVisible] = useState(false);
  const [selectedEmployeeWorkDays, setSelectedEmployeeWorkDays] = useState([]);
  const [timeEdits, setTimeEdits] = useState({});
  const [paidStatus, setPaidStatus] = useState({});
  const [totalPaidSalary, setTotalPaidSalary] = useState(0);
  const [employeeHoursAndWages, setEmployeeHoursAndWages] = useState({});

  const socket = useSocket();
  const managerSalary = 10; // navýšení mzdy zaměstnance, pokud je manažer

  useEffect(() => {
    // calls
    socket.emit("get_employees");
    socket.emit("get_employee_work_hours");

    // listeners
    socket.on("employees_data", (employee) => {
      if (employee && employee.length > 0) {
        employee.forEach((emp) => {
          if (emp.Birthday) {
            emp.Birthday = dayjs(emp.Birthday).format("YYYY-MM-DD");
          }
        });
        setEmployee(employee);
      }
    });
    socket.on("employee_work_hours_data", (workHours) => {
      if (workHours && workHours.length > 0) {
        setEmployeeWork(workHours);

        const updatedWages = calculateHoursAndWages(
          employee,
          workHours,
          selectedDates[0],
          selectedDates[1]
        );
        setEmployeeHoursAndWages(updatedWages);
      }
    });
    socket.on("employee_updated", (updated) => {
      if (updated.success) {
        notification.success({
          message: "Aktualizace",
          description: "Aktualizace zaměstnanecké karty proběhla úspěšně",
          placement: "bottomRight",
          duration: 2,
        });
        socket.emit("get_employees");
      }
    });
    socket.on("employee_added", (added) => {
      if (added.success) {
        notification.success({
          message: "Přidání",
          description: "Přidání zaměstnanecké karty proběhla úspěšně",
          placement: "bottomRight",
          duration: 2,
        });
      } else {
        notification.error({
          message: "Přidání",
          description: "Přidání zaměstnanecké karty proběhla neúspěšně",
          placement: "bottomRight",
          duration: 2,
        });
      }
      socket.emit("get_employees");
    });
    socket.on("employee_work_hours_updated", (updated) => {
      socket.emit("get_employee_work_hours");
    });

    return () => {
      socket.off("employees_data");
      socket.off("employee_work_hours_data");
      socket.off("employee_updated");
      socket.off("employee_added");
      socket.off("employee_work_hours_updated");
    };
  }, [socket]);

  const showEmployeeWorkDaysModal = (employeeId) => {
    let workDays = employeeWork
      .filter((wh) => wh.EmployeeID === employeeId)
      .filter((wh) => {
        if (!selectedDates[0] || !selectedDates[1]) {
          return true;
        }
        const workDate = dayjs(wh.Date);
        return (
          selectedDates[0] &&
          selectedDates[1] &&
          workDate.isSameOrAfter(selectedDates[0], "day") &&
          workDate.isSameOrBefore(selectedDates[1], "day")
        );
      })
      .map((wh) => {
        const start = dayjs(wh.StartTime, "HH:mm:ss");
        const end = dayjs(wh.EndTime, "HH:mm:ss");
        const durationInHours = end.diff(start, "hour", true);
        const hourlyWage = parseFloat(wh.Wage);
        const adjustedHourlyWage =
          wh.Manager === 1 ? hourlyWage + managerSalary : hourlyWage;
        const salary = (adjustedHourlyWage * durationInHours).toFixed(2);

        return {
          recordId: wh.RecordID,
          employeeId: wh.EmployeeID,
          date: dayjs(wh.Date).format("DD-MM-YYYY"),
          startTime: wh.StartTime,
          endTime: wh.EndTime,
          manager: Boolean(wh.Manager),
          salary,
          paid: Boolean(wh.Paid),
        };
      });
    // Seřazení pracovních dnů od nejstaršího po nejnovější
    workDays.sort((a, b) =>
      dayjs(a.date, "DD-MM-YYYY").diff(dayjs(b.date, "DD-MM-YYYY"))
    );

    setSelectedEmployeeWorkDays(workDays);
    setIsWorkDaysModalVisible(true);

    const initialTimeEdits = {};
    const initialPaidStatus = {};
    workDays.forEach((day) => {
      initialTimeEdits[day.recordId] = {
        startTime: day.startTime,
        endTime: day.endTime,
        manager: day.manager,
      };
      initialPaidStatus[day.recordId] = day.paid;
    });
    setTimeEdits(initialTimeEdits);
    setPaidStatus(initialPaidStatus);
  };

  const recalculateTotalPaidSalary = (recordId, checked) => {
    let newTotal = 0;
    if (checked) {
      // Přidání platu zaměstnance, pokud je nyní označen jako "Paid"
      const addedSalary = parseFloat(
        selectedEmployeeWorkDays.find((item) => item.recordId === recordId)
          ?.salary || 0
      );
      newTotal = totalPaidSalary + addedSalary;
    } else {
      // Odečtení platu zaměstnance, pokud je nyní označen jako "neplacený"
      const subtractedSalary = parseFloat(
        selectedEmployeeWorkDays.find((item) => item.recordId === recordId)
          ?.salary || 0
      );
      newTotal = totalPaidSalary - subtractedSalary;
    }
    setTotalPaidSalary(newTotal);
  };

  const calculateSalaryForWorkDay = (
    startTime,
    endTime,
    isManager,
    hourlyWage
  ) => {
    const startMoment = dayjs(startTime, "HH:mm:ss"); // ok
    const endMoment = dayjs(endTime, "HH:mm:ss"); // ok
    const durationHours = endMoment.diff(startMoment, "hour", true); // ok
    const wageMultiplier = isManager ? hourlyWage + managerSalary : hourlyWage;
    return (durationHours * wageMultiplier).toFixed(2);
  };

  const handleTimeChange = (recordId, time, type) => {
    const newTimeEdits = {
      ...timeEdits,
      [recordId]: {
        ...timeEdits[recordId],
        [type]: time ? time.format("HH:mm:ss") : "",
      },
    };
    setTimeEdits(newTimeEdits);

    // Získání aktuálních hodnot pro výpočet výplaty
    const { startTime, endTime, manager } = newTimeEdits[recordId];
    const employeeId = selectedEmployeeWorkDays.find(
      (day) => day.recordId === recordId
    )?.employeeId;

    const hourlyWage =
      employee.find((emp) => emp.EmployeeID === employeeId)?.HourlyWage || 55;

    // Výpočet nové výplaty
    const newSalary = calculateSalaryForWorkDay(
      startTime,
      endTime,
      manager,
      hourlyWage
    );

    // Aktualizace seznamu pracovních dnů s novou výplatou
    const updatedWorkDays = selectedEmployeeWorkDays.map((day) =>
      day.recordId === recordId ? { ...day, salary: newSalary } : day
    );
    setSelectedEmployeeWorkDays(updatedWorkDays);
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      let start = dayjs(dates[0]).startOf("day"); // Set start of the day to 00:00:00
      let end = dayjs(dates[1]).endOf("day"); // Set end of the day to 23:59:59

      // Check if start and end are on the same day
      if (start.isSame(end, "day")) {
        end = start.endOf("day"); // Ensure the end of the day is set to 23:59:59
      }

      setSelectedDates([start, end]);

      // Recalculate wages based on the selected date range
      const updatedWages = calculateHoursAndWages(
        employee,
        employeeWork,
        start,
        end
      );
      setEmployeeHoursAndWages(updatedWages);
    } else {
      setSelectedDates([null, null]);

      // Recalculate wages for the entire dataset since no date range is selected
      const updatedWages = calculateHoursAndWages(
        employee,
        employeeWork,
        null, // Pass null to indicate no date range
        null
      );
      setEmployeeHoursAndWages(updatedWages);
    }
  };
  const handlePaidChange = (recordId, checked) => {
    setPaidStatus((prev) => ({
      ...prev,
      [recordId]: checked,
    }));

    recalculateTotalPaidSalary(recordId, checked);
  };

  const handleManagerChange = (recordId, checked) => {
    // Aktualizace manager statusu
    setTimeEdits((prev) => ({
      ...prev,
      [recordId]: {
        ...prev[recordId],
        manager: checked,
      },
    }));

    // Najděte odpovídající pracovní den a přepočítejte mzdu
    const updatedWorkDays = selectedEmployeeWorkDays.map((day) => {
      if (day.recordId === recordId) {
        const workRecord = employeeWork.find(
          (record) => record.RecordID === recordId
        );
        if (workRecord) {
          const { StartTime, EndTime, Wage } = workRecord;
          const start = dayjs(StartTime, "HH:mm:ss");
          const end = dayjs(EndTime, "HH:mm:ss");
          const durationInHours = end.diff(start, "hour", true);
          const adjustedHourlyWage = checked
            ? parseFloat(Wage) + managerSalary
            : parseFloat(Wage);
          const updatedSalary = adjustedHourlyWage * durationInHours;
          return { ...day, manager: checked, salary: updatedSalary.toFixed(2) };
        }
      }
      return day;
    });

    setSelectedEmployeeWorkDays(updatedWorkDays);
  };

  const handleOpenAddEmployeeModal = () => {
    setIsAddEmployeeModalVisible(true);
  };

  const handleOpenModal = (emp) => {
    setSelectedEmployee(emp);
    setIsModalVisible(true);
    setFormValues({
      ...formValues,
      Birthday: emp.Birthday || "",
    });
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseWorkDaysModal = () => {
    setIsWorkDaysModalVisible(false);
    setTotalPaidSalary(0);
  };

  const handleSave = () => {
    if (selectedEmployee) {
      const updatedData = {
        EmployeeID: selectedEmployee.EmployeeID,
        PersonalNumber: selectedEmployee.PersonalNumber,
        Name: selectedEmployee.Name,
        Surname: selectedEmployee.Surname,
        Birthday: selectedEmployee.Birthday,
        Gender: selectedEmployee.Gender,
        Phone: selectedEmployee.Phone,
        Address: selectedEmployee.Address,
        Town: selectedEmployee.Town,
        HourlyWage: selectedEmployee.HourlyWage,
        ...formValues,
      };

      socket.emit("update_employees", {
        EmployeeID: selectedEmployee.EmployeeID,
        updatedData,
      });

      socket.off("employee_updated");

      socket.on("employee_updated", (response) => {
        if (response.success) {
          setEmployee((prevEmployees) =>
            prevEmployees.map((emp) =>
              emp.EmployeeID === selectedEmployee.EmployeeID
                ? { ...emp, ...updatedData }
                : emp
            )
          );
          socket.emit("get_employee_work_hours");

          notification.success({
            message: "Úspěch",
            description: "Údaje o zaměstnanci byly úspěšně aktualizovány.",
            placement: "bottomRight",
            duration: 2,
          });

          setIsModalVisible(false);
          setSelectedEmployee(null);
          setFormValues({});
        } else {
          notification.error({
            message: "Chyba",
            description: "Nepodařilo se aktualizovat údaje o zaměstnanci.",
            placement: "bottomRight",
            duration: 2,
          });
        }
      });
    }
  };

  const handleSavePayment = () => {
    selectedEmployeeWorkDays.forEach((item) => {
      const { recordId, salary } = item;
      const startTime = timeEdits[recordId].startTime;
      const endTime = timeEdits[recordId].endTime;
      const manager = timeEdits[recordId].manager ? 1 : 0;
      const paid = paidStatus[recordId] ? 1 : 0;

      socket.emit("update_employee_work_hours", {
        recordId,
        startTime,
        endTime,
        salary,
        manager,
        paid,
      });
    });
    notification.success({
      message: "Pracovní hodiny aktualizovány",
      description:
        "Informace o pracovních hodinách byly úspěšně aktualizovány.",
      placement: "bottomRight",
      duration: 2,
    });
    setIsWorkDaysModalVisible(false);
    setTotalPaidSalary(0);
  };

  const handleAddEmployee = () => {
    const newEmployeeData = {
      Name: formValues.Name || "",
      Surname: formValues.Surname || "",
      Birthday: formValues.Birthday || null,
      Gender: formValues.Gender || "",
      Phone: formValues.Phone || "",
      Address: formValues.Address || "",
      Town: formValues.Town || "",
      HourlyWage: parseFloat(formValues.HourlyWage) || 0,
      PersonalNumber: formValues.PersonalNumber || "",
    };

    socket.emit("add_employee", newEmployeeData);
    setIsAddEmployeeModalVisible(false);
  };

  const handleToggleActive = () => {
    if (selectedEmployee) {
      const updatedData = {
        ...selectedEmployee,
        Active: !selectedEmployee.Active,
      };

      socket.emit("update_employees", {
        EmployeeID: selectedEmployee.EmployeeID,
        updatedData,
      });
      setIsModalVisible(false);
    }
  };

  const getEmployeeColor = (name) => {
    switch (name) {
      case "Patrik Dinh":
        return "#A6E5CE";
      case "Tomáš Dinh":
        return "#FFEEAD";
      case "Tereza Hraníčková":
        return "#E9B5B2";
      case "Maria Gurešova":
        return "#AED9E0";
      case "Krisztián Németh":
        return "#a18ed1";
      case "Bao Anh Luong":
        return "#fca08b";
      case "Oleksandra Kalashnyk":
        return "#ba62bc";
      default:
        return "#3a393b"; // defaultní barva
    }
  };

  // Výpočet pracovních hodin pro každého zaměstnance
  const calculateHoursAndWages = (
    employees,
    employeeWorkHours,
    startDate,
    endDate
  ) => {
    const hoursByEmployee = {};
    employees.forEach((emp) => {
      hoursByEmployee[emp.EmployeeID] = { hours: 0, wage: 0 };
    });

    employeeWorkHours.forEach((record) => {
      const recordDate = dayjs(record.Date);

      // If no date range is selected, include all records
      let isInDateRange = true;
      if (startDate && endDate) {
        isInDateRange =
          recordDate.isSame(startDate, "day") ||
          (recordDate.isAfter(startDate.startOf("day")) &&
            recordDate.isBefore(endDate.endOf("day")));
      }

      if (isInDateRange) {
        const { EmployeeID, StartTime, EndTime, Manager, Wage } = record;
        const start = dayjs(StartTime, "HH:mm:ss");
        const end = dayjs(EndTime, "HH:mm:ss");
        let diff = end.diff(start, "hour", true);

        diff = Math.max(0, diff); // Ensure diff is non-negative

        if (!hoursByEmployee[EmployeeID]) {
          hoursByEmployee[EmployeeID] = { hours: 0, wage: 0 };
        }

        hoursByEmployee[EmployeeID].hours += diff;

        const hourlyWage = parseFloat(Wage) || 0;
        const adjustedHourlyWage =
          Manager === 1 ? hourlyWage + managerSalary : hourlyWage;

        hoursByEmployee[EmployeeID].wage += diff * adjustedHourlyWage;
      }
    });

    Object.values(hoursByEmployee).forEach((record) => {
      record.wage = parseFloat(record.wage.toFixed(2)); // Round wage to two decimals
    });

    return hoursByEmployee;
  };

  const calculateUnpaidWages = (employeeWorkHours, startDate, endDate) => {
    const unpaidWagesByEmployee = {};

    employeeWorkHours.forEach((record) => {
      if (record.Paid === 0) {
        const recordDate = dayjs(record.Date);

        // Kontrola, jestli startDate a endDate nejsou null
        let isInDateRange = false;

        if (!startDate || !endDate) {
          isInDateRange = true; // Žádné datumové omezení, pokud jsou obě null
        } else {
          if (startDate.isSame(endDate, "day")) {
            // Pokud jsou startDate a endDate stejné, upravíme logiku pro zahrnutí celého dne
            isInDateRange = recordDate.isSame(startDate, "day");
          } else {
            // Pokud nejsou stejné, použijeme původní logiku pro zjištění, zda je záznam ve vybraném rozmezí
            isInDateRange =
              recordDate.isSameOrAfter(startDate.startOf("day")) &&
              recordDate.isSameOrBefore(endDate.endOf("day"));
          }
        }

        if (isInDateRange) {
          const { EmployeeID, StartTime, EndTime, Manager, Wage } = record;
          const start = dayjs(StartTime, "HH:mm:ss");
          const end = dayjs(EndTime, "HH:mm:ss");
          const diff = end.diff(start, "hour", true);

          if (!unpaidWagesByEmployee[EmployeeID]) {
            unpaidWagesByEmployee[EmployeeID] = 0;
          }

          const adjustedHourlyWage =
            Manager === 1 ? parseFloat(Wage) + managerSalary : parseFloat(Wage);

          if (!isNaN(adjustedHourlyWage)) {
            unpaidWagesByEmployee[EmployeeID] += diff * adjustedHourlyWage;
          }
        }
      }
    });

    Object.keys(unpaidWagesByEmployee).forEach((employeeID) => {
      unpaidWagesByEmployee[employeeID] = parseFloat(
        unpaidWagesByEmployee[employeeID].toFixed(2)
      );
    });

    return unpaidWagesByEmployee;
  };

  const unpaidWages = calculateUnpaidWages(
    employeeWork,
    selectedDates[0],
    selectedDates[1]
  );

  return (
    <div>
      <div>
        <div className="employeeContainerLeftUpper">
          <h1>Zaměstnanci</h1>
        </div>
        <div className="employeeContainerMidUpper">
          <RangePicker
            defaultValue={[dayjs().startOf("month"), dayjs().endOf("month")]}
            format="DD-MM-YYYY"
            onChange={handleDateChange}
            locale={locale}
          />
        </div>
        <div className="employeeContainerRightUpper">
          <Checkbox
            onChange={(e) => {
              setIsNonActiveChecked(e.target.checked);
            }}
            style={{ color: "grey" }} // Změňte barvu textu podle potřeby
          >
            Neaktivní zaměstnanci
          </Checkbox>

          <Button onClick={handleOpenAddEmployeeModal}>
            Přidat zaměstnance
          </Button>
        </div>

        {/* map employee */}
        <div className="AttendanceContainer">
          <div className="AttendanceCombinedContainer">
            {employee.map((emp) => (
              <div
                className="form-container"
                key={emp.EmployeeID}
                style={{
                  display: isNonActiveChecked || emp.Active ? "block" : "none",
                }}
              >
                <form>
                  <div>
                    <div className="imgWithProfileName">
                      <div className="employee-photo-container">
                        <img
                          className="employee-photo"
                          onClick={() => handleOpenModal(emp)}
                          style={{
                            border: `4px solid ${getEmployeeColor(
                              `${emp.Name} ${emp.Surname}`
                            )}`, // Barva ohraničení
                            borderRadius: "50px", // Zaoblení rohů
                            overflow: "hidden", // Přetečení
                            boxShadow: "0 0 15px rgba(0, 0, 0, 1.5)", // Stín
                          }}
                          src={`/employees/${emp.Name}_${emp.Surname}.webp`}
                          onError={(e) => {
                            e.target.src = "/employees/default.webp";
                          }}
                          alt={`${emp.Name} ${emp.Surname}`}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "10px",
                        }}
                      >
                        <p>
                          {/* Jméno: */}{" "}
                          <span
                            className="name"
                            onClick={() => handleOpenModal(emp)}
                            style={{
                              fontWeight: "bold",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.fontSize = "1.05em";
                              e.target.style.color = "teal";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.fontSize = "1em";
                              e.target.style.color = "white";
                            }}
                          >
                            {emp.Name}&nbsp;{emp.Surname}
                          </span>
                        </p>
                      </div>
                    </div>

                    <p>
                      Odpracováno hodin:{" "}
                      {employeeHoursAndWages[
                        emp.EmployeeID
                      ]?.hours.toLocaleString("cs-CZ", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || 0}{" "}
                      h
                    </p>
                    <p>
                      Výplata:{" "}
                      {Math.round(
                        employeeHoursAndWages[emp.EmployeeID]?.wage
                      ).toLocaleString("cs-CZ") || 0}{" "}
                      Kč
                    </p>
                    <p>
                      K vyplacení:{" "}
                      {unpaidWages[emp.EmployeeID] !== undefined &&
                      unpaidWages[emp.EmployeeID] !== null
                        ? Math.round(
                            unpaidWages[emp.EmployeeID]
                          ).toLocaleString("cs-CZ")
                        : "0"}{" "}
                      Kč
                    </p>

                    <p
                      style={{
                        cursor: "pointer",
                        float: "right",
                        marginRight: "10px",
                      }}
                      key={emp.EmployeeID}
                      onClick={() => showEmployeeWorkDaysModal(emp.EmployeeID)}
                    >
                      •••
                    </p>
                  </div>
                </form>
              </div>
            ))}
          </div>
        </div>
        {/* Add Employee */}
        <Modal
          title="Přidat zaměstnance"
          open={isAddEmployeeModalVisible}
          onCancel={() => setIsAddEmployeeModalVisible(false)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setIsAddEmployeeModalVisible(false)}
            >
              Zrušit
            </Button>,
            <Button key="save" type="primary" onClick={handleAddEmployee}>
              Uložit
            </Button>,
          ]}
        >
          <Form>
            <Form.Item label="Rodné Číslo">
              <Input
                value={formValues.PersonalNumber}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    PersonalNumber: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Jméno">
              <Input
                value={formValues.Name}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    Name: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Příjmení">
              <Input
                value={formValues.Surname}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    Surname: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Narození">
              <DatePicker
                format="DD-MM-YYYY"
                value={
                  formValues.Birthday ? dayjs(formValues.Birthday) : undefined
                }
                onChange={(date, dateString) => {
                  const formattedDate = date ? date.format("YYYY-MM-DD") : null;
                  setFormValues({
                    ...formValues,
                    Birthday: formattedDate,
                  });
                }}
                locale={locale}
              />
            </Form.Item>
            <Form.Item label="Pohlaví">
              <Select
                value={formValues.Gender}
                onChange={(value) => {
                  setFormValues({
                    ...formValues,
                    Gender: value,
                  });
                }}
              >
                <Select.Option value="Muž">Muž</Select.Option>
                <Select.Option value="Žena">Žena</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Telefon">
              <Input
                value={formValues.Phone}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    Phone: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Adresa">
              <Input
                value={formValues.Address}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    Address: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Město">
              <Input
                value={formValues.Town}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    Town: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Hodinová mzda">
              <Input
                value={formValues.HourlyWage}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    setFormValues({
                      ...formValues,
                      HourlyWage: inputValue,
                    });
                  }
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        {/* Edit Employee */}
        <Modal
          title="Zaměstnanecká karta"
          open={isModalVisible}
          onCancel={handleCloseModal}
          footer={[
            <Button key="toggleActive" onClick={handleToggleActive}>
              {selectedEmployee && selectedEmployee.Active
                ? "Deactivate"
                : "Activate"}
            </Button>,
            <Button key="cancel" onClick={handleCloseModal}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSave}>
              Save
            </Button>,
          ]}
        >
          {selectedEmployee && (
            <Form>
              <Form.Item label="Rodné Číslo">
                <Input
                  value={
                    formValues.PersonalNumber || selectedEmployee.PersonalNumber
                  }
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      PersonalNumber: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Jméno">
                <Input
                  value={formValues.Name || selectedEmployee.Name}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Name: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Příjmení">
                <Input
                  value={formValues.Surname || selectedEmployee.Surname}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Surname: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Narození">
                <DatePicker
                  format="DD-MM-YYYY"
                  value={
                    formValues.Birthday ? dayjs(formValues.Birthday) : undefined
                  }
                  onChange={(date, dateString) => {
                    // Při změně ukládáme do stavu datum ve formátu YYYY-MM-DD, i když uživatel vidí DD-MM-YYYY
                    const formattedDate = date
                      ? date.format("YYYY-MM-DD")
                      : null;
                    setFormValues({
                      ...formValues,
                      Birthday: formattedDate,
                    });
                  }}
                  locale={locale}
                />
              </Form.Item>
              <Form.Item label="Pohlaví">
                <Select
                  value={formValues.Gender || selectedEmployee.Gender}
                  onChange={(value) => {
                    setFormValues({
                      ...formValues,
                      Gender: value,
                    });
                  }}
                >
                  <Select.Option value="Muž">Muž</Select.Option>
                  <Select.Option value="Žena">Žena</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Telefon">
                <Input
                  value={formValues.Phone || selectedEmployee.Phone}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Phone: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Adresa">
                <Input
                  value={formValues.Address || selectedEmployee.Address}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Address: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Město">
                <Input
                  value={formValues.Town || selectedEmployee.Town}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      Town: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Hodinová mzda">
                <Input
                  value={formValues.HourlyWage || selectedEmployee.HourlyWage}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      setFormValues({
                        ...formValues,
                        HourlyWage: inputValue,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Form>
          )}
        </Modal>
        {/* Show Working Days */}
        <Modal
          title="Směny"
          className="modalWorkDays"
          open={isWorkDaysModalVisible}
          onCancel={handleCloseWorkDaysModal}
          footer={[
            <div key="totalPaid" className="modalFooter">
              <span className="totalPaidSalary">
                Vybraný plat k proplacení:{" "}
                <span className="text">{totalPaidSalary.toFixed(0)} Kč</span>
              </span>
              <Button
                key="back"
                className="shift-close-button"
                onClick={handleCloseWorkDaysModal}
              >
                Zavřít
              </Button>
              <Button key="submit" type="primary" onClick={handleSavePayment}>
                Uložit
              </Button>
            </div>,
          ]}
        >
          <List
            className="modalWorkDaysList"
            dataSource={selectedEmployeeWorkDays}
            renderItem={(item) => (
              <List.Item>
                <div className="items">
                  <span className="itemsDate">{item.date}</span>

                  <TimePicker
                    className="no-clear-button"
                    value={dayjs(
                      timeEdits[item.recordId].startTime,
                      "HH:mm:ss"
                    )}
                    format="HH:mm"
                    onChange={(time) =>
                      handleTimeChange(item.recordId, time, "startTime")
                    }
                  />
                  <TimePicker
                    className="no-clear-button"
                    value={dayjs(timeEdits[item.recordId].endTime, "HH:mm:ss")}
                    format="HH:mm"
                    onChange={(time) =>
                      handleTimeChange(item.recordId, time, "endTime")
                    }
                  />
                  <Checkbox
                    checked={timeEdits[item.recordId].manager}
                    onChange={(e) =>
                      handleManagerChange(item.recordId, e.target.checked)
                    }
                  >
                    Vedoucí
                  </Checkbox>
                  {employeeWork.map((record) => {
                    if (record.RecordID === item.recordId) {
                      return (
                        <span key={record.RecordID}>
                          <span className="wage">
                            • Hodinová mzda:{" "}
                            <span className="text">{record.Wage}</span> Kč
                          </span>
                          <span className="salary">
                            • Výplata:{" "}
                            <span className="text">
                              {Math.round(item.salary)}
                            </span>{" "}
                            Kč
                          </span>
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Checkbox
                    checked={paidStatus[item.recordId]}
                    onChange={(e) =>
                      handlePaidChange(item.recordId, e.target.checked)
                    }
                  >
                    Vyplaceno?
                  </Checkbox>
                </div>
              </List.Item>
            )}
          />
        </Modal>
      </div>
      <div>
        <EmployeesGraph getEmployeeColor={getEmployeeColor} />
      </div>
    </div>
  );
}

export default Employees;
